<template>
    <section
        v-if="imageBefore && imageAfter"
        class="section"
    >
        <ImgComparisonSlider>
            <img
                slot="first"
                :src="imageBefore"
                class="w-full h-full"
            >
            <img
                slot="second"
                :src="imageAfter"
                class="w-full h-full"
            >
            <div
                v-if="showCustomHandle"
                slot="handle"
                class="flex items-center justify-center gap-5 p-5 xl:p-12 rounded-large overflow-hidden bg-black/60 backdrop-blur-md select-none"
            >
                <div class="max-xl:text-sm">{{ textBefore }}</div>
                <div class="select-none w-[30px] h-[30px] xl:w-[52px] xl:h-[52px] bg-[url('/img/icons/arrows-small.svg')] xl:bg-[url('/img/icons/arrows.svg')]"></div>
                <div class="max-xl:text-sm">{{ textAfter }}</div>
            </div>
        </ImgComparisonSlider>
    </section>
</template>

<script setup>
    import { ref } from 'vue';
    import { ImgComparisonSlider } from '@img-comparison-slider/vue';

    defineProps(['imageBefore', 'imageAfter', 'textBefore', 'textAfter']);

    const showCustomHandle = ref(window.innerWidth > 640);

    window.addEventListener('resize', () => {
        showCustomHandle.value = window.innerWidth > 640;
    });
</script>