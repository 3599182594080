import './bootstrap';
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';

import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import './components/toastify';

import { createApp } from 'vue';
import VueWrapper from "./components/VueWrapper.vue";
import ImageSlide from "./components/ImageSlide.vue";
import ProjectsLightbox from "./components/ProjectsLightbox.vue";
import VueSelect from 'vue-select';

createApp({
    components: {
        VueWrapper,
        VueSelect
    },
    data() {
        return {
            selected: ''
        }
    },
}).mount('#contact-form');

createApp({
    components: {
        ImageSlide,
    },
}).mount('#image-slide');

createApp({
    components: {
        ProjectsLightbox,
    },
}).mount('#lightbox');

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

window.onload = function () {
    AOS.init({
        offset: 0,
        // duration: 1000,
        delay: 0,
        once: true,
        anchorPlacement: 'top-center',
    });
};

new Swiper('#swiper-projects', {
    slidesPerView: 3,
    spaceBetween: 20,
    autoplay: {
        delay: 3000
    },
    loop: true,
    speed: 1000,
    modules: [Autoplay],
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20
        }
    }
});

new Swiper('#swiper-clients', {
    slidesPerView: 1,
    autoplay: {
        delay: 3000
    },
    loop: true,
    speed: 1000,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        nextEl: '.swiper-clients-next',
        prevEl: '.swiper-clients-prev',
    },
    modules: [Navigation, EffectFade],
});

new Swiper('#swiper-working', {
    slidesPerView: 4,
    spaceBetween: 20,
    autoplay: {
        delay: 3000
    },
    loop: true,
    speed: 1000,
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        580: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        900: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1280: {
            slidesPerView: 4,
            spaceBetween: 20
        }
    },
    navigation: {
        nextEl: '.swiper-working-next',
        prevEl: '.swiper-working-prev',
    },
    modules: [Navigation, Autoplay],
});



