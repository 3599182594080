<template>
    <div class="grid grid-cols-1 lg:grid-cols-5 grid-flow-dense max-lg:gap-y-5 lg:gap-5">
        <div
            v-for="(image, imgIndex) in gallery"
            :key="imgIndex"
            class="max-sm:h-[300px] max-lg:h-[500px] max-lg:w-full flex items-center justify-center overflow-hidden cursor-pointer odd:rounded-tl-medium odd:xl:rounded-tl-large odd:rounded-br-medium odd:xl:rounded-br-large even:rounded-tr-medium even:xl:rounded-tr-large even:rounded-bl-medium even:xl:rounded-bl-large"
            :class="{ 'lg:h-[900px]': rowspan(image.rowspan) === 2, 'lg:h-[430px]': rowspan(image.rowspan) === 1 }"
            :style="`grid-column: span ${colspan(image.colspan)}; grid-row: span ${rowspan(image.rowspan)}`"
            @click="openLightbox(imgIndex)"
        >
            <img :src="image.path_webp" class="w-full h-full object-cover hover:scale-125 transition-transform duration-500">
        </div>

        <FsLightbox
            :toggler="toggler"
            :sources="images"
            :slide="slide"
        ></FsLightbox>
    </div>
</template>

<script setup>
    import FsLightbox from "fslightbox-vue/v3";
    import { ref } from 'vue';

    defineProps(['images', 'gallery']);

    const toggler = ref(false);
    const slide = ref(1);

    const openLightbox = (imgIndex) => {
        toggler.value = !toggler.value;
        slide.value = imgIndex + 1;
    }

    const colspan = (value) => {
        if (value < 1) {
            return 1;
        }

        if (value > 5) {
            return 5;
        }

        return value;
    }

    const rowspan = (value) => {
        if (value < 1) {
            return 1;
        }

        if (value > 2) {
            return 2;
        }

        return value;
    }
</script>